import React, {useEffect, useState} from "react";
import {GatsbyImage, StaticImage} from "gatsby-plugin-image"
import Link from "gatsby-link";
import {graphql} from "gatsby";
import {Button, Container, ImageList, ImageListItem, ImageListItemBar} from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMedium} from '@fortawesome/free-brands-svg-icons'
import Layout from "../components/Layout/ru";
import ToSimulatorButton from "../components/ToSimulatorButton";
import * as styles from './style.module.css';


const IndexRu = ({data, location}) => {
	const [isWindow, setWindow] = useState(false);

	useEffect(() => {
		setWindow(typeof window !== `undefined`)
	}, [])

	const topics = data.allMarkdownRemark.edges;
	const frontendImageEdge = data.allFile.edges.filter((item) => item.node.relativePath === "front_end.jpg")[0]
	const backendImageEdge = data.allFile.edges.filter((item) => item.node.relativePath === "back_end.jpg")[0]

	return (
		<Layout
			title='PhilipNedelev.DEV'
			description='Сайт для начинающих JavaScript разработчиков'
			location={location}
		>
			<Container maxWidth="md">
				<h1>Сайт для начинающих JS разработчиков</h1>

				<div className={styles.promo}>
					<Link to={`/ru/author`}>
						<StaticImage
							className={styles.avatar}
							src="../../static/img/avatar.png"
							placeholder="blurred"
							alt="мое фото"
						/>
					</Link>
					<div className={styles.promoContent}>
						<p>
							Привет!<br/>
							Здесь ты найдешь полезную информацию которая поможет тебе в учебе и устройстве на работу
						</p>
					</div>
				</div>

				{/*<div className={styles.mentor}>*/}
				{/*	<h2>Индивидуальные консультации и менторинг</h2>*/}
				{/*	<p>Часовые созвоны на которых я помогу тебе: </p>*/}
				{/*	<ul>*/}
				{/*		<li>разобраться как работают в IT</li>*/}
				{/*		<li>разобраться с технологиями, направлениями в IT и рабочими практиками</li>*/}
				{/*		<li>разобрать любые вопросы которые возникают в процессе обучения</li>*/}
				{/*		<li>построить план обучения</li>*/}
				{/*		<li>подготовится к собеседованию</li>*/}
				{/*	</ul>*/}
				{/*	<p>Часовая консультация стоит $50</p>*/}
				{/*	<Button variant="contained"*/}
				{/*					style={{backgroundColor:"#0088cc",marginTop:20}}*/}
				{/*					startIcon={<TelegramIcon />}*/}
				{/*	>*/}
				{/*		Напиши мне в Telegram и мы забукаем созвон*/}
				{/*	</Button>*/}
				{/*</div>*/}



				<div className={styles.roadmaps}>
					<h2>
						JS Roadmaps {new Date().getFullYear()}
					</h2>
					<div className={styles.wrapper}>
						<Link to={`/ru/front-end-roadmap`}>
							<div className={styles.item}
									 style={{backgroundImage: `url(${frontendImageEdge.node.childImageSharp.gatsbyImageData.images.fallback.src})`}}
							>
								<div className={styles.opacity}></div>
								<h3>Front-End Roadmap</h3>
							</div>
						</Link>
						<Link to={`/ru/back-end-roadmap`}>
							<div className={styles.item}
									 style={{backgroundImage: `url(${backendImageEdge.node.childImageSharp.gatsbyImageData.images.fallback.src})`}}
							>
								<div className={styles.opacity}></div>
								<h3>Back-End Roadmap </h3>
							</div>
						</Link>
					</div>
				</div>


				{/*<div className={styles.simulator}>*/}
				{/*	<StaticImage*/}
				{/*		className={styles.image}*/}
				{/*		src="../../static/img/simulator.png"*/}
				{/*		placeholder="blurred"*/}
				{/*		alt="логотип React"*/}
				{/*	/>*/}
				{/*	<div className='content'>*/}
				{/*		<h2>React Практикум</h2>*/}
				{/*		<span style={{lineHeight: "27px"}}>*/}
				{/*			Добавь проект в свое портфолио выполняя задачи из реальных проектов и получая фидбек в код ревью*/}
				{/*		</span>*/}
				{/*		<div className='buttons' style={{marginTop: 15}}>*/}
				{/*			<Link to='/ru/react-practicum'>*/}
				{/*				<Button style={{backgroundColor: "#ffe100", color: "#000"}} variant="contained">*/}
				{/*					Узнать больше*/}
				{/*				</Button>*/}
				{/*			</Link>*/}
				{/*			<ToSimulatorButton/>*/}
				{/*		</div>*/}
				{/*	</div>*/}
				{/*</div>*/}


				<div className={styles.answers}>
					<h2>
						Вопросы на собеседованиях
					</h2>
					<ImageList sx={{}}
										 cols={3}
										 // cols={isWindow && window.screen.width >= 480 ? 3 : 1}
					>
						{topics.map(({node: {frontmatter}}) => (
							<ImageListItem
								key={frontmatter.title}
								style={{height: isWindow && window.screen.width >= 350 ? "auto" : 120}}>
								<Link to={`/ru/${frontmatter.questions_page}`}>
									<GatsbyImage
										image={frontmatter.image.childImageSharp.gatsbyImageData}
										alt={frontmatter.title}
										// style={{height: isWindow && window.screen.width >= 350 ? "auto" : 120}}
									/>
									<ImageListItemBar position="bottom" title={frontmatter.title}/>
								</Link>
							</ImageListItem>
						))}
					</ImageList>
				</div>
			</Container>
		</Layout>
	);
};

export const query = graphql`
    {
        allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "topics"}}}) {
            edges {
                node {
                    id
                    frontmatter {
                        title,
                        questions_page,
                        image {
                            childImageSharp {
                                gatsbyImageData(
                                    placeholder: DOMINANT_COLOR
                                    formats: [AUTO, WEBP, AVIF]
                                    width: 350
                                    height: 350
                                )
                            }
                        }
                        slag
                    }
                }
            }
        }
        allFile(filter: {extension: {eq: "jpg"}}) {
            edges {
                node {
                    relativePath
                    childImageSharp {
                        gatsbyImageData(placeholder: DOMINANT_COLOR, formats: WEBP)
                    }
                }
            }
        }
    }
`

export default IndexRu;
